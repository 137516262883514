<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="py-8"
          title="Small-Scale Burning Test"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="Small-Scale Burning Test Description"
              size="medium"
            />
            <a
              :href="
                require('../../assets/IPSeries/SmallscaleBurnDetailed-min.png')
              "
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/SmallscaleBurnDetailed-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of completing the small-scale burning test is to
              determine whether an explosion occurs when an unconfined substance
              is exposed to fire.
            </p>
            <TitleComponent
              class="py-8"
              title="Test Variations"
              size="medium"
            />
            <p class="p-light">
              For solids, instead of a beaker, a conical pile of the substance
              can be tested, ignited by Kraft paper.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <div>
                <table class="test-table">
                  <tbody>
                    <tr>
                      <th>Key Parameter</th>
                      <th>Objectives</th>
                      <th>Origin</th>
                      <th>Specs</th>
                    </tr>
                    <tr>
                      <td>Sample mass</td>
                      <td>
                        Provide sufficient mass to enable accurate
                        characterization
                      </td>
                      <td></td>
                      <td>
                        (2) 10-gram samples<br />
                        (2) 100-gram samples
                      </td>
                    </tr>
                    <tr>
                      <td>Fuel type and quantity</td>
                      <td>
                        Create a fire of sufficient intensity and duration that
                        engulfs and consumes the product to enable
                        characterization
                      </td>
                      <td></td>
                      <td>
                        Approximately 100 g of sawdust and 200 mL of kerosene.
                        Kerosene-soaked sawdust bed (specified as 30cm x 30cm x
                        1.3cm or 2.5cm) or Kraft paper
                      </td>
                    </tr>
                    <tr>
                      <td>Container</td>
                      <td>
                        Readily allow the substance to be ignited with minor
                        confinement
                      </td>
                      <td></td>
                      <td>
                        Thin-walled plastics beaker, just sufficient in size to
                        contain the test substance and compatible with it
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Explosion</td>
                    <td>
                      Observation of consumption rate and visual post-test
                      inspection
                    </td>
                    <td>
                      Observation compared against the definition of a mass
                      explosion hazard (one which affects almost the entire load
                      virtually instantaneously).
                    </td>
                  </tr>
                  <tr>
                    <td>Burn time of material</td>
                    <td>Visual observation with the aid of a timepiece</td>
                    <td>May be noted but does not affect the conclusion</td>
                  </tr>
                  <tr>
                    <td>Time to explosion</td>
                    <td>Visual observation with the aid of a timepiece</td>
                    <td>May be noted but does not affect the conclusion</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Setup Example" size="medium" />
            <a
              :href="require('../../assets/UN/burn-setup-min.jpg')"
              target="_blank"
            >
              <img
                style="width:50%;"
                class="m-auto"
                src="@/assets/UN/burn-setup-min.jpg"
              />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Ignite and Burn (-) Example Photo"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/nogoburn-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/IPSeries/nogoburn-min.jpg" />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Ignite and Burn (-) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166130653?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166130653"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Explosion (+) Example Photo"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/burngo-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/UN/burngo-min.jpg" />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Explosion (+) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166130651?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166130651"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
